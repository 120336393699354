<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button @click.prevent="clockInOut" variant="primary" v-if="!user?.client">
          <b-spinner class="mx-4" small v-if="loading" />
          <span v-if="!loading">{{ clockedIn ? 'Clock Out' : 'Clock In' }}</span>
        </b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-4">
        <b-col cols="12">
          <b-card>
            <p class="h4 font-weight-bold mb-3">Good {{ greeting }}, {{ user.name }}</p>
            <p class="mb-0 text-muted" v-if="user.type === 'staff'">Here's an overview of bookings and cleaning jobs on across all properties.</p>
            <p class="mb-0 text-muted" v-if="user.type === 'owner'">Here's an overview of bookings and costs involving your property.</p>
            <p class="mb-0 text-muted" v-if="user.type === 'cleaner'">Here's an overview of your jobs today.</p>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="can('Users/View')">
        <b-col cols="12" lg="6">
          <b-card no-body>
            <h4 class="card-title m-4">Today's Arrivals</h4>
            <b-card-body class="d-flex flex-column" v-if="bookingsLoading">
              <b-spinner class="align-self-center" variant="primary" />
              <p class="align-self-center mt-1 mb-0">Loading...</p>
            </b-card-body>
            <b-list-group flush v-if="!bookingsLoading">
              <b-list-group-item class="text-center py-5" v-if="!arrivingBookingsData.length">
                <p class="h4 font-weight-bold">No Results</p>
                <p class="text-muted">There are no bookings where someone is arriving today.</p>
              </b-list-group-item>
              <b-list-group-item class="d-flex" :key="item.id" :to="{name: 'bookings.update', params: {bookingId: item.id}}" v-for="item in arrivingBookingsData">
                <div class="align-self-center flex-fill">
                  <p class="mb-1 font-weight-bold">{{ item.customer && item.customer.name }} @ {{ item.property && item.property.name }}</p>
                  <p class="mb-0 text-muted">{{ moment(item.start_date).format('DD/MM/YYYY') }}
                    &dash; {{ moment(item.end_date).format('DD/MM/YYYY') }}
                    <span :class="{
                        'text-danger': moment(item.property.last_booked).isAfter(item.property.last_cleaned),
                        'text-success': moment(item.property.last_booked).isBefore(item.property.last_cleaned)
                    }" v-if="item.property.last_cleaned">Last cleaned {{ moment(item.property.last_cleaned).fromNow() }}</span></p>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col cols="12" lg="6">
          <b-card no-body>
            <h4 class="card-title m-4">Today's Departures</h4>
            <b-card-body class="d-flex flex-column" v-if="bookingsLoading">
              <b-spinner class="align-self-center" variant="primary" />
              <p class="align-self-center mt-1 mb-0">Loading...</p>
            </b-card-body>
            <b-list-group flush v-if="!bookingsLoading">
              <b-list-group-item class="text-center py-5" v-if="!departingBookingsData.length">
                <p class="h4 font-weight-bold">No Results</p>
                <p class="text-muted">There are no bookings where someone is departing today.</p>
              </b-list-group-item>
              <b-list-group-item class="d-flex" :key="item.id" :to="{name: 'bookings.update', params: {bookingId: item.id}}" v-for="item in departingBookingsData">
                <div class="align-self-center flex-fill">
                  <p class="mb-1 font-weight-bold">{{ item.customer && item.customer.name }} @ {{ item.property && item.property.name }}</p>
                  <p class="mb-0 text-muted">{{ moment(item.start_date).format('DD/MM/YYYY') }} &dash; {{ moment(item.end_date).format('DD/MM/YYYY') }}</p>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-card no-body>
            <h4 class="card-title m-4">Today's Clock Ins</h4>
            <b-card-body class="d-flex flex-column" v-if="loading">
              <b-spinner class="align-self-center" variant="primary" />
              <p class="align-self-center mt-1 mb-0">Loading...</p>
            </b-card-body>
            <b-list-group flush v-if="!loading">
              <b-table responsive class="mb-0" :items="todayStaffData" :fields="['name', 'start_time', 'end_time']">

              </b-table>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="can('Users/View')">
        <b-col cols="12">
          <b-card no-body>
            <h4 class="card-title m-4">Today's Cleaning Jobs</h4>
            <b-card-body class="d-flex flex-column" v-if="loading">
              <b-spinner class="align-self-center" variant="primary" />
              <p class="align-self-center mt-1 mb-0">Loading...</p>
            </b-card-body>
            <b-list-group flush v-if="!loading">
              <b-table responsive class="mb-0" style="white-space: pre-wrap;" :items="todayRotaData" :fields="['name', 'team_name', 'start', 'end', 'completed', 'staff_clocked_in', 'actual_start_end', '_']">
                <template #cell(_)="row">
                  <b-button :to="'/jobs/details/' + row.item.id" variant="primary" size="sm">View</b-button>
                </template>
              </b-table>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="can('Users/View')">
        <b-col cols="12">
          <b-card no-body>
            <h4 class="card-title m-4">Today's Maintenance Jobs</h4>
            <b-card-body class="d-flex flex-column" v-if="loading">
              <b-spinner class="align-self-center" variant="primary" />
              <p class="align-self-center mt-1 mb-0">Loading...</p>
            </b-card-body>
            <b-list-group flush v-if="!loading">
              <b-table responsive class="mb-0" style="white-space: pre-wrap;" :items="todayMaintenanceData" :fields="['name', 'user', 'start', 'end', 'staff_clocked_in', 'actual_start_end', '_']">
                <template #cell(_)="row">
                  <b-button :to="'/maintenance/details/' + row.item.maintenanceId" variant="primary" size="sm">View</b-button>
                </template>
              </b-table>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { StickyHeader },
  async mounted() {
    const self = this;

    if (this.can('Users/View')) {
      await this.bookingsFetch({ page: 1, dashboard: 'departing' }).then(function(data) {
        self.departingBookingsData = data.data
        self.todayJobsData = data.data
      });
      await this.bookingsFetch({ page: 1, dashboard: 'arriving' }).then(function(data) {
        self.arrivingBookingsData = data.data
      });
      if (this.user.type !== 'owner') {
        await this.rotaFetch({before: moment().format('Y-MM-DD'), after: moment().format('Y-MM-DD'), userId: null}).then(function(data) {
          self.outstandingJobsData = data.data
        });
      }

      await this.maintenanceDashboard().then(function(data) {
        data.forEach(function(maintenance) {
          // Loop all visits and map the attendable against it.
          // The attendable sits on the top level of the maintenance object, so it will be required to map them based on time.
          maintenance.visits.forEach(function(visit) {
            self.todayMaintenanceData[visit.id] = {
              id: visit?.id,
              maintenanceId: maintenance?.id,
              name: maintenance?.property?.name ?? 'Unknown',
              user: maintenance?.user?.name ?? 'Unknown',
              start: moment(moment(visit.visit_at).format('YYYY-MM-DD') + ' ' + visit.start_time).format('HH:mm'),
              end: moment(moment(visit.visit_at).format('YYYY-MM-DD') + ' ' + visit.end_time).format('HH:mm'),
              staff_clocked_in: maintenance?.attendance.map((data) => data?.user?.name ?? 'Unknown').join('\n'),
              actual_start_end: maintenance?.attendance.map((data) => (data?.start ? moment(data.start).format('HH:mm') : 'Pending') + ' - ' + (data?.end ? moment(data.end).format('HH:mm') : 'Pending')).join('\n'),
            }
          })
        })
      })

      await this.fetch({dashboard: 1});

      if (this.user.type !== 'owner') {
        await this.jobsFetch({ page: 1, dashboard: 'today' });
      }
    }
    if (this.user.type !== 'owner') {
      await this.jobsFetch({ page: 1, dashboard: 'today' })
    }
  },
  data() {
    return {
      departingBookingsData: [],
      arrivingBookingsData: [],
      outstandingJobsData: [],
      todayRotaData: [],
      todayStaffData: [],
      todayMaintenanceData: []
    }
  },
  computed: {
    ...mapState('timesheets', ['loading', 'clockedIn', 'data']),
    ...mapState('bookings', { bookingsLoading: 'loading' }),
    ...mapState('jobs', { jobsLoading: 'loading' }),
    ...mapState('rotas', { rotaLoading: 'loading', rotaData: 'data' }),
    ...mapState('maintenance', { maintenanceLoading: 'loading', maintenanceData: 'data' }),
    ...mapState('auth', ['user']),
    greeting() {
      return moment().get('hour') < 12 ? 'morning' : 'afternoon'
    }
  },
  methods: {
    ...mapActions('timesheets', ['clockIn', 'clockOut', 'fetch']),
    ...mapActions('bookings', { bookingsFetch: 'fetch' }),
    ...mapActions('jobs', { jobsFetch: 'fetch' }),
    ...mapActions('rotas', { rotaFetch: 'fetch' }),
    ...mapActions('maintenance', { maintenanceDashboard: 'dashboard' }),
    moment,
    clockInOut() {
      if (this.clockedIn) {
        this.clockOut().then((data) => {
          this.$bvModal.msgBoxOk(data.message, {
            title: 'Clock Out',
            okVariant: data.clockedIn ? 'danger' : 'primary',
            centered: true,
            okTitle: data.job ? 'View Job' : 'Ok'
          }).then(() => {
            if (data.job) {
              this.$router.push({name: 'jobs.details', params: {'jobId': data.job.id}});
            }
          });
        }).catch(() => {
          this.$bvModal.msgBoxOk('There was an error clocking you out.', {
            title: 'Clock Out',
            okVariant: 'danger',
            centered: true
          });
        });
      } else {
        this.clockIn().then((data) => {
          this.$bvModal.msgBoxOk(data.message, {
            title: 'Clock In',
            okVariant: !data.clockedIn ? 'danger' : 'primary',
            centered: true
          });
        }).catch(() => {
          this.$bvModal.msgBoxOk('There was an error clocking you in.', {
            title: 'Clock In',
            okVariant: 'danger',
            centered: true
          });
        })
      }
    },
    can(perm) {
      let pass = false
      this.user.permissions.forEach((permission) => {
        if (permission.name == perm) pass = true;
      });

      return pass;
    }
  },
  watch: {
    data(data) {
      let self = this;

      this.todayStaffData = map(data, function(staff) {
        if ((self.user.type == 'cleaner') && (self.user.id != staff.attendable_id)) return;

        return {
          name: staff.attendable.name,
          start_time: moment(staff.start).format('HH:mm'),
          end_time: staff.end ? moment(staff.end).format('HH:mm') : 'Pending',
          _rowVariant: staff.end ? 'success' : undefined
        }
      })
    },
    rotaData(data) {
      this.todayRotaData = [];

      let self = this;

      data.forEach(function (data) {
        data.jobs.forEach(function(job) {
          self.todayRotaData.push({
            id: job?.id,
            name: job?.property?.name ?? job?.customer?.name ?? 'Unknown',
            team_name: data?.team?.name ?? 'Unknown',
            start: moment(job.started_at).format('HH:mm'),
            end: moment(job.started_at).add(job?.duration_min ?? 0, 'minutes').format('HH:mm'),
            completed: job.completed_at ? 'Yes' : 'No',
            staff_clocked_in: job?.attendance.map((data) => data?.user?.name ?? 'Unknown').join('\n'),
            actual_start_end:
              job?.attendance.map((data) => (data?.start ? moment(data.start).format('HH:mm') : 'Pending') + ' - ' + (data?.end ? moment(data.end).format('HH:mm') : 'Pending')).join('\n'),
            _rowVariant: job.completed_at ? 'success' : undefined
          })
        })
      });
    }
  }
}
</script>

<style>
.card-title {
  font-weight: bold;
}
</style>
